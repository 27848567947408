import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useIsCleary from 'components/common/hooks/useIsCleary'
import { present } from 'components/common/utils'
import useConfigureNavItems from 'components/navbar/hooks/useConfigureNavItems'
import { LeftNavItemType } from 'components/navbar/hooks/useLeftNavItems'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.navbar')

const useAdminLeftNavItems = () => {
  const { permissions } = useCurrentUser()
  const { settings, type: companyType } = useCurrentCompany()
  const { configureNavItems, hasConfigureAccess } = useConfigureNavItems()
  const isCleary = useIsCleary()

  const isAiPlatform = companyType === 'ai_platform'

  const communicationsSubItems = [
    settings.news.enabled && permissions.articleCreator && { label: I18N('articles'), to: '/admin/articles' },
    settings.banners.enabled && permissions.bannerManager && { label: I18N('banners'), to: '/admin/banners' },
    settings.surveys.enabled && permissions.surveyCreator && { label: I18N('surveys'), to: '/admin/surveys' },
  ].filter(present)

  const journeysSubItems = [
    { label: I18N('journeys_dashboard'), to: '/admin/journeys_dashboard' },
    settings.journeys.stepTypes.tasks.enabled && { label: I18N('tasks'), to: '/admin/tasks' },
    { label: I18N('journeys'), to: '/admin/journeys' },
    settings.journeys.onboardingBuddies.enabled && permissions.journeyManager && { label: I18N('onboarding_buddies'), to: '/admin/onboarding_buddies' },
    settings.journeys.preboarding.enabled && permissions.journeyManager && { label: I18N('preboarding_experience'), to: '/admin/preboarding_experience' },
  ].filter(present)

  const analyticsSubItems = [
    permissions.analyticsAdmin && { label: I18N('dashboard'), to: '/platform_analytics' },
    permissions.superAdmin && !isAiPlatform && { label: I18N('reports'), to: '/admin/metrics' },
  ].filter(present)

  const clearyAdminSubItems = [
    { label: I18N('settings'), to: '/admin/cleary_admin' },
    isCleary && { label: I18N('global_analytics'), to: '/admin/global_analytics' },
    isCleary && { label: I18N('ai_evaluation'), to: '/admin/ai_evaluation' },
    isCleary && { label: I18N('styleguide'), to: '/admin/styleguide' },
    isCleary && { label: I18N('companies'), to: '/admin/companies' },
  ].filter(present)

  const adminNavItems: LeftNavItemType[] = [
    !_.isEmpty(communicationsSubItems) && {
      label: I18N('communications'),
      icon: '/images/navbar/communicationsIcon.svg',
      to: communicationsSubItems[0].to,
      activePaths: ['/admin/articles', '/admin/banners', '/admin/surveys'],
      subItems: communicationsSubItems,
    },
    settings.ticketing?.enabled && permissions.ticketingManager && {
      label: I18N('helpdesk'),
      icon: '/images/navbar/helpdeskIcon.svg',
      to: '/admin/tickets',
    },
    settings.journeys.enabled && permissions.journeyCollaborator && {
      label: I18N('journeys'),
      icon: '/images/navbar/journeysIcon.svg',
      to: journeysSubItems[0].to,
      activePaths: [
        '/admin/journeys_dashboard',
        '/admin/tasks',
        '/admin/journeys',
        '/admin/onboarding_buddies',
        '/admin/preboarding_experience',
      ],
      subItems: journeysSubItems,
    },
    permissions.itManager && {
      label: I18N('external_sources'),
      icon: '/images/navbar/externalSourcesIcon.svg',
      to: '/admin/external_sources',
    },
    hasConfigureAccess && {
      label: I18N('configure'),
      icon: '/images/navbar/configureIcon.svg',
      to: configureNavItems[0].to,
      activePaths: [
        '/admin/users',
        '/admin/user_imports',
        '/admin/teams',
        '/admin/managed_groups',
        '/admin/user_settings',
        '/admin/restricted_audiences',
        '/admin/billing',
        '/admin/app_integrations',
        '/admin/apps',
        '/admin/external_calendars',
        '/admin/cleary_ai_settings',
        '/admin/homepage_design',
        '/admin/carousel_cards',
        '/admin/timezones',
        '/admin/branding',
        '/admin/logos',
        '/admin/workflows',
      ],
    },
    !_.isEmpty(analyticsSubItems) && {
      label: I18N('analytics'),
      icon: '/images/navbar/analyticsIcon.svg',
      to: analyticsSubItems[0].to,
      activePaths: ['/platform_analytics', '/admin/metrics'],
      subItems: analyticsSubItems,
    },
    permissions.clearyAdmin && {
      label: I18N('cleary_admin'),
      icon: '/images/navbar/clearyAdminIcon.svg',
      to: clearyAdminSubItems[0].to,
      activePaths: ['/admin/cleary_admin', '/admin/styleguide', '/admin/companies', '/admin/global_analytics', '/admin/ai_evaluation'],
      subItems: clearyAdminSubItems,
    },
  ].filter(present)

  return { adminNavItems, hasAdminAccess: !_.isEmpty(adminNavItems) }
}

export default useAdminLeftNavItems
